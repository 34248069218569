export default [
  {
    header: 'Ayarlar',
  },
  {
    title: 'Bildirimler',
    icon: 'BellIcon',
    route: 'bildirim',
  },
  {
    title: 'Kullanicilar',
    icon: 'UsersIcon',
    route: 'kullanicilar',
  },
  {
    title: 'Ayarlar',
    icon: 'SettingsIcon',
    route: 'ayarlar',
  },
  // {
  //   title: 'Dashboard Ayarları',
  //   icon: 'SettingsIcon',
  //   route: 'dashboard-ayarlari',
  // },
];
