import { useUtils as useI18nUtils } from '@core/libs/i18n';
import store from '@/store';

const { t } = useI18nUtils();

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  render(h) {
    const span = h('span', {}, t(`menu.${this.item.header}`));
    if (store.getters.getUserData.master == 'admin') {
      return h('li', { class: 'navigation-header text-truncate text-primary' }, [span]);
    } else {
      if (this.item.header != 'yonetim') {
        return h('li', { class: 'navigation-header text-truncate text-primary' }, [span]);
      }
    }
    return h();
  },
};
