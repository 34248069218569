<template>
  <li class="navigation-header text-truncate text-primary" v-show="false">
    <span>
      {{ this.item.header }}
    </span>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
