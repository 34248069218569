export default [
  {
    header: 'İçerikler',
  },
  {
    title: 'Web Yönetimi',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'Anasayfa',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Sayfalar',
        route: 'sayfalar',
        icon: 'BookIcon',
      },
      {
        title: 'Slider',
        route: 'slider',
        icon: 'ImageIcon',
      },
      {
        title: 'Galeri',
        route: 'galeri',
        icon: 'ImageIcon',
      },
      {
        title: 'İletişim',
        route: 'iletisim',
        icon: 'PhoneIcon',
      },
    ],
  },
];
