export default [
  {
    title: 'Firmalar',
    icon: 'CommandIcon',
    route: 'firmalar',
  },
  {
    title: 'Kur İşlemleri',
    route: 'kur-islemleri',
  },
];
