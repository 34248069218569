/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard';
import webYonetim from './webYonetim';
import rezervasyon from './rezervasyon';
import tanimlamalar from './tanimlamalar';
import ayarlar from './ayarlar';
import yonetim from './yonetim';

// Array of sections
export default [...dashboard, ...webYonetim, ...rezervasyon, ...tanimlamalar, ...ayarlar, ...yonetim];
