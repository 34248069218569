<template>
  <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.name || '' }}
        </p>
        <span class="user-status">{{ userData.email || '' }}</span>
      </div>
      <b-avatar size="40" :src="userProfile" variant="light-primary" badge class="badge-minimal" badge-variant="success">
        <feather-icon v-if="!userData.name" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <b-dropdown-item :to="{ name: 'pages-profile' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>Profil</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'pages-account-setting' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span>Şifremi Değiştir</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'apps-todo' }" link-class="d-flex align-items-center" v-show="false">
      <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
      <span>Yapılacaklar</span>
    </b-dropdown-item>
    <b-dropdown-divider />

    <b-dropdown-item :to="{ name: 'pages-pricing' }" link-class="d-flex align-items-center" v-show="false">
      <feather-icon size="16" icon="CreditCardIcon" class="mr-50" />
      <span>Lisans</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'pages-faq' }" link-class="d-flex align-items-center" v-show="false">
      <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
      <span>Yardım</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Çıkış Yap</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import { initialAbility } from '@/libs/acl/config';
import useJwt from '@/auth/jwt/useJwt';
import { avatarText } from '@core/utils/filter';

export default {
  components: {},
  data() {
    return {
      userData: this.$store.getters.getUserData,
      avatarText,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
  },
  computed: {
    userProfile() {
      const base_url =
        process.env.NODE_ENV === 'development'
          ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
          : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);
      return `${base_url}uploads/profile/${this.userData.profile}`;
    },
  },
};
</script>
