<template>
  <div>
    <b-nav-item-dropdown id="dropdown-company" variant="link" class="dropdown-language" right v-if="master == 'admin'">
      <template #button-content>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex flex-column text-right">
            <p class="user-name d-block font-weight-bolder mb-0">
              {{ selectFirma.firma_adi != null ? firmaName(selectFirma.firma_adi) : 'Firmalar' }}
            </p>
            <span style="font-size: 10px">{{ selectFirma.domain != null ? selectFirma.domain : 'Firma Seçiniz' }}</span>
          </div>
          <div class="pl-50" v-if="selectFirma.k_no">
            <feather-icon class="text-danger" size="21" icon="XSquareIcon" @click="selectedFirma({}, true)" />
          </div>
        </div>
      </template>
      <b-dropdown-item v-for="firma in firmalar" :key="firma.k_no" @click="selectedFirma(firma, true)">
        <span class="ml-50">{{ firmaName(firma.firma_adi) }}</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>

    <div class="d-flex flex-column text-right" v-else>
      <p class="user-name d-block font-weight-bolder mb-0">
        {{ firmaName(selectFirma.firma_adi) }}
      </p>
      <span style="font-size: 10px">{{ selectFirma.domain }}</span>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { onMounted, computed, ref } from '@vue/composition-api';
import { kisalt } from '@/libs/variables';
export default {
  components: {},
  setup() {
    const expo = {};
    expo.firmalar = ref([]);
    expo.selectFirma = ref({
      k_no: null,
      firma_adi: null,
      domain: null,
    });
    expo.master = ref('user');

    expo.firmaName = computed(() => {
      return (value) => {
        if (value) {
          return kisalt(value, 15);
        }
      };
    });

    onMounted(() => {
      const user = store.getters.getUserData;
      const firma = store.getters.getUserFirma;
      expo.master.value = user.master;

      const { VUE_APP_DEFAULT_DB } = process.env;

      if (expo.master.value == 'admin') {
        store.dispatch('firmalar').then((res) => {
          expo.firmalar.value = res;
          if (firma.db != VUE_APP_DEFAULT_DB) {
            const selectFirma = expo.firmalar.value.find((x) => x.k_no == firma.k_no);
            expo.selectedFirma(selectFirma, false);
          }
        });
      } else {
        store.dispatch('firmalar', user.firma_k_no).then((res) => {
          expo.selectedFirma(res[0], false);
        });
      }
    });

    expo.selectedFirma = (firma, refresh) => {
      const { VUE_APP_DEFAULT_DB } = process.env;

      if (firma != null || firma != undefined) {
        console.log(firma);
        expo.selectFirma.value = {
          k_no: firma.k_no,
          firma_adi: firma.firma_adi,
          domain: firma.domain,
          db: firma.db,
          modul: firma.modul,
        };
        store.commit('SET_SELECT_FIRMA_DB', firma.db);
        store.commit('SET_USER_FIRMA', expo.selectFirma.value);
      } else {
        expo.selectFirma.value = {
          k_no: null,
          firma_adi: null,
          domain: null,
        };
        store.commit('SET_SELECT_FIRMA_DB', VUE_APP_DEFAULT_DB);
      }

      refresh ? window.location.reload() : false;
      return;
    };

    return { ...expo };
  },
};
</script>

<style></style>
