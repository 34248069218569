import { render, staticRenderFns } from "./verticalNavMenuHeader.vue?vue&type=template&id=a5241b00&scoped=true&"
import script from "./verticalNavMenuHeader.vue?vue&type=script&lang=js&"
export * from "./verticalNavMenuHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5241b00",
  null
  
)

export default component.exports