import moment from 'moment';
const str_shuffle = (str) => {
  var newStr = [];

  if (arguments.length < 1) {
    throw 'str_shuffle : Parameter str not specified';
  }

  if (typeof str !== 'string') {
    throw 'str_shuffle : Parameter str ( = ' + str + ') is not a string';
  }

  str = str.split('');
  while (str.length) {
    newStr.push(str.splice(Math.floor(Math.random() * (str.length - 1)), 1)[0]);
  }

  return newStr.join('');
};

const registerNumber = (prefix = 'VRD') => {
  const str = 'ABCDEFGHJKLMNOPUQRSTUVWXYZ';
  const int = '1234567890';
  const newPass = prefix + moment().format('YYMMDDHHmmss') + str_shuffle(str).substr(0, 2);
  return newPass;
};

const kisalt = (str, max) => {
  const textLenght = str.length;
  const suffix = textLenght < max ? '' : ' ...';
  const text = str.substr(0, max);
  return text + suffix;
};

export { registerNumber, kisalt };
