<template>
  <li
    v-if="userYetkiler(item)"
    class="nav-item"
    :class="{
      active: isActive,
    }"
  >
    <b-link v-bind="linkProps" class="d-flex align-items-center">
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ item.title }}</span>
      <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { ref, computed } from '@vue/composition-api';
import { useUtils as useAclUtils } from '@core/libs/acl';
import useVerticalNavMenuLink from './useVerticalNavMenuLink';
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink';
import { userYetki } from '@/libs/role';
export default {
  components: {},
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item);
    const { canViewVerticalNavMenuLink } = useAclUtils();

    const userYetkiler = computed(() => {
      return (val) => userYetki(val);
    });

    return {
      userYetkiler,
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,
    };
  },
};
</script>
