<template>
  <b-nav-item-dropdown class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right>
    <template #button-content>
      <feather-icon :badge="bildirimler.length" badge-classes="bg-danger" class="text-body" icon="BellIcon" size="21" />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Bildirimler</h4>
        <b-badge pill variant="light-primary"> Yeni {{ bildirimler.length }} bildirim </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="media-list scroll-area" tagname="li">
      <b-link v-for="bildirim in bildirimler" :key="bildirim._id">
        <b-media @click="handlerGoLink(bildirim)">
          <template #aside>
            <b-avatar size="32" text="R" variant="primary" />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ bildirim.mesaj }}
            </span>
          </p>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>
    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        variant="primary"
        block
        :to="{
          name: 'bildirim',
        }"
      >
        Tüm Bildirimler
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { mapGetters } from 'vuex';
export default {
  components: {
    VuePerfectScrollbar,
  },
  computed: {
    ...mapGetters(['getBildirimler']),
  },
  data: () => ({
    perfectScrollbarSettings: {
      maxScrollbarLength: 60,
    },
    bildirimler: [],
  }),
  created() {
    this.fetchItems();
  },
  methods: {
    async fetchItems() {
      await this.$store.dispatch('bildirimListele', { statu: false }).then((res) => {
        this.bildirimler = res.data.data;
      });
    },
    async handlerGoLink(task) {
      await this.$store.dispatch('bildirimGuncelle', task).then((res) => {
        if (res.data.success == true) {
          this.fetchItems();
          this.$router.push({ name: 'rezervasyon-detay', params: { k_no: task.islem_k_no } });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-area {
  position: relative;
  max-height: 400px;
}
</style>
