import store from '@/store';

export const userYetki = (item) => {
  const firmaModul = store.getters.getUserFirma.modul;
  const roller = store.getters.getUserRole.authority;
  const master = store.getters.getUserData.master;
  if (master === 'admin') {
    return true;
  } else {
    if (firmaModul == 'tanitim') {
      // Tanıtım web sitelerinin modulleri ve yetkilendirmeleri
      for (const key in roller) {
        if (item.route === 'dashboard') return true;
        if (roller[key].modul == 'web-yonetim') {
          if (
            item.route == 'anasayfa' ||
            item.route == 'sayfalar' ||
            item.route == 'slider' ||
            item.route == 'galeri' ||
            item.route == 'iletisim'
          ) {
            return roller[key]['listeleme'];
          }
        }

        if (roller[key].modul == 'tanimlamalar') {
          if (
            item.route == 'kategoriler'
            // item.route == 'bolgeler' ||
            // item.route == 'iptal-politikalari' ||
            // item.route == 'taglar' ||
            // item.route == 'ekstra-hizmetler' ||
            // item.route == 'kur-islemleri'
          ) {
            return roller[key]['listeleme'];
          }
        }
        if (roller[key].modul == 'kullanicilar') {
          if (item.route == 'kullanicilar') {
            return roller[key]['listeleme'];
          }
        }
        if (roller[key].modul == 'ayarlar') {
          if (item.route == 'ayarlar') {
            return roller[key]['listeleme'];
          }
        }
      }
    } else if (modul == 'tur') {
      // Tur web sitelerinin modulleri ve yetkilendirmeleri
      for (const key in roller) {
        if (item.route === 'dashboard') return true;
        if (roller[key].modul == 'web-yonetim') {
          if (
            item.route == 'anasayfa' ||
            item.route == 'sayfalar' ||
            item.route == 'slider' ||
            item.route == 'galeri' ||
            item.route == 'iletisim'
          ) {
            return roller[key]['listeleme'];
          }
        }
        if (roller[key].modul == 'urun-islemleri') {
          if (item.route == 'urun') {
            return roller[key]['listeleme'];
          }
        }
        if (roller[key].modul == 'rezervasyon-islemleri') {
          if (item.route == 'rezervasyonlar' || item.route == 'bildirim') {
            return roller[key]['listeleme'];
          }
        }
        if (roller[key].modul == 'finans-islemleri') {
          if (item.route == 'finans') {
            return roller[key]['listeleme'];
          }
        }
        if (roller[key].modul == 'yorum-islemleri') {
          if (item.route == 'yorumlar') {
            return roller[key]['listeleme'];
          }
        }
        if (roller[key].modul == 'uye-islemleri') {
          if (item.route == 'uyeler') {
            return roller[key]['listeleme'];
          }
        }

        if (roller[key].modul == 'tanimlamalar') {
          if (
            item.route == 'kategoriler' ||
            item.route == 'bolgeler' ||
            item.route == 'iptal-politikalari' ||
            item.route == 'taglar' ||
            item.route == 'ekstra-hizmetler' ||
            item.route == 'kur-islemleri'
          ) {
            return roller[key]['listeleme'];
          }
        }
        if (roller[key].modul == 'kullanicilar') {
          if (item.route == 'kullanicilar') {
            return roller[key]['listeleme'];
          }
        }
        if (roller[key].modul == 'ayarlar') {
          if (item.route == 'ayarlar') {
            return roller[key]['listeleme'];
          }
        }
      }
    }

    return false;
  }
};
export const userPaketYetkiler = (item) => {
  let modul = store.getters.getPaket.modul;
  let master = store.getters.getUserData.master;

  if (master === 'admin') {
    return true;
  } else {
    if (item.title == 'dashboard') {
      return true;
    }
    // Header bolumu yetkileri
    if (item.header) {
      if (item.header == 'hotspotIslemleri') {
        if (modul.free_internet_modulu || modul.satis_modulu) return true;
      }
      if (item.header == 'crmIslemleri') {
        if (
          modul.hotel_info ||
          modul.hotel_kroki ||
          modul.hotel_etkinlik ||
          modul.bos_mesajlar ||
          modul.anket_hizmetleri ||
          modul.spahamam ||
          modul.alacarte ||
          modul.arac_kiralama ||
          modul.oda_servis ||
          modul.oda_temizlik ||
          modul.kuru_temizleme ||
          modul.teknik_servis ||
          modul.tatil_uzatma
        )
          return true;
      }
      if (item.header == 'operasyonIslemleri') {
        if (modul.operasyon_moulu) return true;
      }
      if (item.header == 'ayarlarRaporlar') {
        return true;
      }
      if (item.header == 'yonetim') {
        return false;
      }
    }

    if (item.title == 'bagliCihazlar') {
      if (modul.free_internet_modulu || modul.satis_modulu) return true;
    }

    // Header Altında bulunan acılır menu yetkılerı
    if (item.children) {
      if (item.title == 'freeHizmetler') {
        if (modul.free_internet_modulu) return true;
      }
      if (item.title == 'satisHizmetleri') {
        if (modul.satis_modulu) return true;
      }
      if (item.title == 'crmAltIslemler' || item.title == 'crmAltTanimlamalar') {
        if (
          modul.hotel_info ||
          modul.hotel_kroki ||
          modul.hotel_etkinlik ||
          modul.bos_mesajlar ||
          modul.anket_hizmetleri ||
          modul.spahamam ||
          modul.alacarte ||
          modul.arac_kiralama ||
          modul.oda_servis ||
          modul.oda_temizlik ||
          modul.kuru_temizleme ||
          modul.teknik_servis ||
          modul.tatil_uzatma
        )
          return true;
      }
      if (
        item.title == 'operasyonAltIslemler' ||
        item.title == 'operasyonAltTanimlamalar' ||
        item.title == 'operasyonAltRaporlar'
      ) {
        if (modul.operasyon_moulu) return true;
      }
      if (item.title == 'ayarlar' || item.title == 'raporlar') {
        return true;
      }
    }
    return false;
  }
};
